import { NgxPermissionsModule } from 'ngx-permissions';
import { SafePipe } from './components/safePipe';
import { FileSizePipe } from './pipes/filesize.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MaterialModule } from '../common/material/material.module';
import { RouterModule } from '@angular/router';
import { AppInfoComponent } from './components/main-layout/components/app-info/app-info.component';
import { UserInfoComponent } from './components/main-layout/components/user-info/user-info.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { MapComponent } from './components/map/map.component';
import { LayerTreeComponent } from './components/map/layer-tree/layer-tree.component';
import { MapConfigService } from './services/map-config-servce';
import { LayerFactoryService } from './services/layer-factory-service';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { MatConfirmDialogComponent } from './components/mat-confirm-dialog/mat-confirm-dialog.component';
import { NoItemsComponent } from './components/no-items/no-items.component';
import { StyleFactoryService } from './services/style-factory-service';
import { MapService } from './services/map-service';
import { MeasurementPanelComponent } from './tools/measurement-panel/measurement-panel.component';
import { ProjectionService } from './services/projection-service';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { PrintPanelComponent } from './tools/print-panel/print-panel.component';
import { PrintService } from './services/print-service';
import { EditingPanelComponent } from './tools/editing-panel/editing-panel.component';
import { MapHttpService } from './services/map-http-service';
import { InfoPanelComponent } from './tools/info-panel/info-panel.component';
import { ObjectAssignPanelComponent } from './tools/object-assign-panel/object-assign-panel.component';
import { SnappingPanelComponent } from './tools/snapping-panel/snapping-panel.component';
import { GravePanelComponent } from './tools/grave-panel/grave-panel.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DrawingPanelComponent } from './tools/draw/drawing-panel/drawing-panel.component';
import { AttributesFormComponent } from './tools/draw/attributes-form/attributes-form.component';
import { SnappingManagerService } from './services/map/snapping-manager-service';
import { DrawInfoPanelComponent } from './tools/draw/draw-info-panel/draw-info-panel.component';
import { MapEventBusService } from './services/map/map-event-bus-service';
import { PendingChangesConfirmationComponent } from './tools/draw/pending-changes-confirmation/pending-changes-confirmation.component';
import { NoPermissionsComponent } from './components/no-permissions/no-permissions.component';
import { MapProfileChooserComponent } from './tools/map-profile-chooser/map-profile-chooser.component';
import { BreadcrumbComponent } from './components/breadcrumbs/breadcrumbs.component';
import { GraveInfoPanelComponent } from './tools/grave-info-panel/grave-info-panel.component';
import { LegendPanelComponent } from './tools/legend-panel/legend-panel.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-D',
  },
  display: {
    dateInput: 'YYYY-MM-D',
    monthYearLabel: 'YYYY MM',
    dateA11yLabel: 'YYYY-MM-D',
    monthYearA11yLabel: 'YYYY MM',
  },
};


@NgModule({
  declarations: [
    MainLayoutComponent,
    SidebarComponent,
    FileSizePipe,
    AppInfoComponent,
    UserInfoComponent,
    MatConfirmDialogComponent,
    UserInfoComponent,
    MapComponent,
    LayerTreeComponent,
    NoItemsComponent,
    MeasurementPanelComponent,
    PrintPanelComponent,
    EditingPanelComponent,
    InfoPanelComponent,
    ObjectAssignPanelComponent,
    SnappingPanelComponent,
    GravePanelComponent,
    DrawingPanelComponent,
    AttributesFormComponent,
    DrawInfoPanelComponent,
    PendingChangesConfirmationComponent,
    NoPermissionsComponent,
    MapProfileChooserComponent,
    BreadcrumbComponent,
    GraveInfoPanelComponent,
    LegendPanelComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    NgxPermissionsModule,
    ClickOutsideModule,
    HttpClientJsonpModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    MapComponent,
    LayerTreeComponent,
    NoItemsComponent,
    FileSizePipe,
    NgxPermissionsModule,
    ClickOutsideModule,
    BreadcrumbComponent
  ],
  providers: [
    ErrorInterceptor,
    MapConfigService,
    LayerFactoryService,
    StyleFactoryService,
    MapService,
    MapHttpService,
    ProjectionService,
    SnappingManagerService,
    MapEventBusService,
    PrintService,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: ErrorInterceptor,
      multi: true
    },
    ConfirmDialogService,
    { provide: MAT_DATE_LOCALE, useValue: 'pl' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  entryComponents: [
    MatConfirmDialogComponent,
    AttributesFormComponent,
    PendingChangesConfirmationComponent,
    DrawingPanelComponent
  ]
})
export class CoreModule { }
