import { Injectable } from '@angular/core';
import { MapConfig } from '../models/map-config.model';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class MapConfigService {

  private _apiUserPrefix = environment.apiPrefix + '/map_configs';

  mapConfigInstances: { [key: string]: MapConfig } = {};


  constructor(private _http: HttpClient) { }

  static getOsmMapConfig(): MapConfig {
    return {
      printConfigUrl: ' ',
      dataProjection: 'EPSG:2177',
      center: [20.09950, 50.90920],
      zoom: 14,
      layers: [{
        type: 'osm',
        name: 'OpenStreetMap',
        layerName: 'osm',
        visible: true
      }],
      snappingLayers: [],
      minimapLayers: []
    };
  }

  static getCommonMapConfig(): MapConfig {
    return {
      printConfigUrl: ' ',
      dataProjection: 'EPSG:2177',
      center: [20.09950, 50.90920],
      zoom: 15,
      layers: [{
        type: 'osm',
        name: 'OpenStreetMap',
        layerName: 'osm',
        visible: true
      }, {
        type: 'group',
        name: 'Gesut',
        visible: true,
        layers: [{
          type: 'wms',
          url: 'http://137.74.0.169:8080/geoserver/wms',
          layerName: 'krasocin_workspace:gesut_wod_urzadztech',
          name: 'Urządzenia wodociągowe',
          visible: true
        },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_wod_sieci',
            name: 'Sieci wodociągowe',
            visible: true
          },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_wod_budowle',
            name: 'Budowle wodociągowe',
            visible: true
          },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_kan_urzadztech',
            name: 'Urządzenia kanalizacyjne',
            visible: true
          },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_kan_sieci',
            name: 'Sieci kanalizacyjne',
            visible: true
          },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_kan_budowle',
            name: 'Budowle kanalizacyjne',
            visible: true
          }
        ]
      }, {
        type: 'group',
        name: 'Adresy',
        visible: true,
        layers: [{
          type: 'wms',
          url: 'http://137.74.0.169:8080/geoserver/wms',
          layerName: 'krasocin_workspace:emuia_punktyadresowe',
          name: 'Numery adresowe',
          visible: true
        }]
      }, {
        type: 'group',
        name: 'EGIB',
        visible: true,
        layers: [{
          type: 'wms',
          url: 'http://137.74.0.169:8080/geoserver/wms',
          layerName: 'krasocin_workspace:egib_obreby',
          name: 'Obręby',
          visible: true
        },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:egib_dzialki',
            name: 'Działki',
            visible: true
          },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:egib_budynki',
            name: 'Budynki',
            visible: true
          }]
      }],
      snappingLayers: [],
      minimapLayers: []
    };

  }

  static getAccidentMapConfig(): MapConfig {
    return {
      printConfigUrl: ' ',
      dataProjection: 'EPSG:2177',
      center: [20.09950, 50.90920],
      zoom: 15,
      layers: [{
        type: 'osm',
        name: 'OpenStreetMap',
        layerName: 'osm',
        visible: true
      }, {
        type: 'group',
        name: 'Gesut',
        visible: true,
        layers: [{
          type: 'wms',
          url: 'http://137.74.0.169:8080/geoserver/wms',
          layerName: 'krasocin_workspace:gesut_wod_urzadztech',
          name: 'Urządzenia wodociągowe',
          visible: true
        },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_wod_sieci',
            name: 'Sieci wodociągowe',
            visible: true
          },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_wod_budowle',
            name: 'Budowle wodociągowe',
            visible: true
          },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_kan_urzadztech',
            name: 'Urządzenia kanalizacyjne',
            visible: true
          },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_kan_sieci',
            name: 'Sieci kanalizacyjne',
            visible: true
          },
          {
            type: 'wms',
            url: 'http://137.74.0.169:8080/geoserver/wms',
            layerName: 'krasocin_workspace:gesut_kan_budowle',
            name: 'Budowle kanalizacyjne',
            visible: true
          },
        ]
      }],
      snappingLayers: [],
      minimapLayers: []
    };
  }

  getMapConfig2(type: string): Observable<any> {

    const simpleObservable = new Observable((observer) => {
      observer.next(this.getLocalPrintableConfig());
      observer.complete();
    });

    return simpleObservable;

  }

  getLocalPrintableConfig(): MapConfig {

    return {
      printConfigUrl: 'http://localhost/geoportal/proxy/proxy.php?url=http://localhost:8080/geoserver/pdf/info.json',
      dataProjection: 'EPSG:2177',
      center: [19.320, 50.72520],
      zoom: 14,
      layers: [{
        type: 'osm',
        name: 'OpenStreetMap',
        layerName: 'osm',
        visible: true
      }, {
        type: 'wms',
        url: 'http://localhost:8080/geoserver/wms',
        layerName: 'netgis:wojewodztwa',
        name: 'Wojewodztwa',
        visible: true
      }, {
        type: 'wms',
        url: 'http://localhost:8080/geoserver/wms',
        layerName: 'netgis:dzialki',
        name: 'Dzialki',
        visible: true,
        routingInfo: 'sewer-networks/details',
        editable: true
      }, {
        type: 'wfs',
        url: 'http://localhost:8080/geoserver/wfs',
        layerName: 'netgis:linie',
        name: 'Linie',
        visible: true,
        editable: true
      }
      ],
      snappingLayers: ['Linie'],
      minimapLayers: []
    };
  }

  getMapConfig(type: string): Observable<any> {

    if (this.mapConfigInstances[type]) {
      return of(this.mapConfigInstances[type]);
    } else {
      return this._http.get<any>(this._apiUserPrefix + '/' + type)
        .pipe(map(response => {
          const jsonString = JSON.stringify(response.config.replace(/[\n]/g, '').replace(/'/g, '"'));
          const mapConfig = JSON.parse(JSON.parse(jsonString));
          this.mapConfigInstances[type] = mapConfig;
          return mapConfig;
        }));

    }
  }

  getProfileConfig(url: string): Observable<any> {
      return this._http.get<any>(environment.apiPrefix + '/' + url)
        .pipe(map(response => {
          const result = [];
          for (const item of response['hydra:member']) {
            result.push({name: item.nazwa, mapConfigName: item.mapConfigName + '_app'});
          }
          return result;
        }));
  }

  getPrintConfig(url: string): Observable<any> {
    return this._http.get<any>(url);
  }







}
