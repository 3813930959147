import {EventEmitter, Injectable, Output} from '@angular/core';
import Map from 'ol/Map.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import {StyleFactoryService} from './style-factory-service';
import Geometry from 'ol/geom/Geometry.js';
import WKT from 'ol/format/WKT.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Style } from 'ol/style.js';
import {LayerFactoryService} from './layer-factory-service';
import Collection from 'ol/Collection.js';
import {MapConfig} from '../models/map-config.model';
import {ProjectionService} from './projection-service';
import LayerGroup from 'ol/layer/Group';
import {get as getProjection} from 'ol/proj';
import Feature from 'ol/Feature';

@Injectable()
export class MapService {

  @Output() wfsLayerAdded = new EventEmitter();
  @Output() event = new EventEmitter();

  public map: Map;
  public mapConfig: MapConfig;
  private initialDataLayer: VectorLayer;
  public editableLayer: VectorLayer;

  constructor(
    private styleFactory: StyleFactoryService,
    private projectionService: ProjectionService,
    private layerFactory: LayerFactoryService
    ) {
  }

  init(map: Map, mapConfig: MapConfig) {
    this.map = map;
    this.mapConfig = mapConfig;
  }

  loadSpatialData(geometryJson: string): VectorLayer {
    if (!this.initialDataLayer) {
      this.initialDataLayer = this.addTemporaryLayerWithStyle('Wybrany obiekt', this.styleFactory.createSelectFeatureStyle());
    }

    this.initialDataLayer.getSource().clear();
    const geometry = this.fromGeojson(geometryJson);
    geometry.transform(this.mapConfig.dataProjection, 'EPSG:3857');
    const feature = new Feature({
      geometry: geometry
    });
    this.initialDataLayer.getSource().addFeature(feature);

    this.zoomToLayer(this.initialDataLayer);
    this.editableLayer = this.initialDataLayer;
    return this.initialDataLayer;
  }

  zoomToLayer(layer) {
    const extent = layer.getSource().getExtent();
    this.map.getView().fit(extent, {size: this.map.getSize()});
  }

  addTemporaryLayer(name: string): VectorLayer {
    return this.addTemporaryLayerWithStyle(name, null);
  }

  addTemporaryLayerWithStyle(name: string, style: Style): VectorLayer {
    const layer = this.createTemporaryLayer(name, style);
    this.map.addLayer(layer);

    layer.layerConfig = {
      type: 'tmp',
      printable: true
    };

    return layer;
  }

  addWfsLayerFromWmsLayer(layerName: string): VectorLayer {
    const layer = this.findLayerByName(layerName);

    const layerConfig = {
      type: 'wfs',
      url: layer.layerConfig.url,
      layerName: layerName,
      name: layer.layerConfig.name
    };

    const vectorLayer = this.layerFactory.createLayer(layerConfig, this.getSrs());
    this.map.addLayer(vectorLayer);

    this.wfsLayerAdded.emit(vectorLayer);
    return vectorLayer;
  }

  createTemporaryLayer(name: string, layerStyle: Style) {
    if (!layerStyle) {
      layerStyle = this.styleFactory.createPolygonStyle();
    }

    return new VectorLayer({
      name: name,
      source:  new VectorSource({}),
      style: layerStyle
    });
  }

  toWkt(geometry: Geometry): string {
    const format = new WKT();
    return format.writeGeometry(geometry);
  }

  toFeatureGeojson(feature: Feature): string {
    const format = new GeoJSON();
    return format.writeFeatureObject(feature);
  }

  toGeojson(geometry: Geometry): string {
    const format = new GeoJSON();
    return format.writeGeometry(geometry);
  }

  fromGeojson(geometry: string): Geometry {
    const format = new GeoJSON();
    return format.readGeometry(geometry);
  }

  getResolutionForScaleAndDpi(map: Map, scale, dpi) {
    const projection = map.getView().getProjection();

    const mpu = projection.getMetersPerUnit();
    const inchesPerMeter = 39.37;
    const res = scale / (mpu * inchesPerMeter * dpi);
    return res;
  }

  getLayers() {
    return this.getLayersAsFlatArray(this.map.getLayers().getArray());
  }

  changeLayerVisibility(layerName, visible) {
    if (layerName) {
      const layer = this.findLayerByName(layerName);
      if (layer) {
        layer.visible = visible;
        layer.setVisible(visible);
      }
    }
  }

  getLayersAsFlatArray(mapLayers: any[]): any[] {
    let layers = [];

    for (const i in mapLayers) {
      if (mapLayers[i] instanceof LayerGroup) {
        layers = layers.concat(this.getLayersAsFlatArray(mapLayers[i].getLayers().getArray()));
      } else {
        layers.push(mapLayers[i]);
      }
    }
    return layers;
  }

  findLayerByName(layerName: string) {
    return this.findLayerByProperty('layerName', layerName);
  }

  findLayerByProperty(property: string, value: string) {
    const layers = this.getLayers();
    for (const i in layers) {
      if (layers[i].layerConfig[property] === value) {
        return layers[i];
      }
    }
    return null;
  }

  getLayersAttributeByProperty(attribute: string, property: string, value: string) {
    const result: string[] = [];
    const layers = this.getLayers();
    for (const i in layers) {
      if (layers[i].layerConfig[property] === value) {
        result.push(layers[i].layerConfig[attribute]);
      }
    }
    return result;
  }

  getLayerByProperty(property: string, value: string) {
    const layers = this.getLayers();
    for (const i in layers) {
      if (layers[i].layerConfig[property] === value) {
        return layers[i];
      }
    }
  }

  getLayersByProperty(property: string, value: any) {
    const result: string[] = [];
    const layers = this.getLayers();
    for (const i in layers) {
      if (layers[i].layerConfig && layers[i].layerConfig[property] === value) {
        result.push(layers[i]);
      }
    }
    return result;
  }

  getLayersByProperties(propertyValues: any[]) {
    const result: string[] = [];
    const layers = this.getLayers();
    for (const layer of layers) {
      const layerConfig = layer.layerConfig;
      let match = true;
      for (const pv of propertyValues) {
        if (layerConfig[pv.property] !== pv.value) {
          match = false;
          break;
        }
      }
      if (match) {
        result.push(layer);
      }
    }
    return result;
  }

  getAllLayers() {
    const result = new Array();
    this.extractLayers(this.map.getLayers().getArray(), result);
    return result;
  }

  extractLayers(layers, result) {

    for (const i in layers) {
      if (this.isGroup(layers[i])) {
        this.extractLayers(layers[i].getLayers().getArray(), result);
      } else {
        result.push(layers[i]);
      }
    }
  }

  isGroup(layer) {
    if ( layer instanceof LayerGroup) {
      return true;
    }
    return false;
  }

  getLayersByConfigValues(configs) {
    const result = [];
    const layers = this.getAllLayers();

    for ( let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      let matched = true;

      for ( let j = 0; j < configs.length; j++) {
        const config = configs[j];
        if (!this.doesMatchProperty(layer, config.property, config.value)) {
          matched = false;
          break;
        }
      }

      if (matched === true) {
        result.push(layer);
      }
    }

    return result;
  }

  doesMatchProperty(layer, property, value) {

    if (layer.get(property) === value) {
      return true;
    }

    if (layer.layerConfig && layer.layerConfig[property] === value) {
      return true;
    }

    return false;
  }

  getCurrentScale() {
    return this.getScaleForResolution(this.map.getView().getResolution());
  }

  getScaleForResolution(resolution: number) {
    const view = this.map.getView();
    const mpu = view.getProjection().getMetersPerUnit();
    const inchesPerMeter = 39.37;
    const dpi = 25.4 / 0.28;

    return resolution * mpu * inchesPerMeter * dpi;
  }

  getSrs() {
    if (this.map) {
      return this.map.getView().getProjection().getCode();
    } else {
      return this.mapConfig.dataProjection;
    }
  }

  getMaxExtent() {
    let projectionExtent;
    const srs = this.getSrs();

    if (srs === 'EPSG:4326' || srs === 'EPSG:900913' || srs === 'EPSG:3857') {
      projectionExtent = getProjection(srs).getExtent();
    } else {
      projectionExtent = this.projectionService.getExtent(srs);
    }

    return projectionExtent;
  }

  getFeatures(layers: VectorLayer[]): any[] {
    const features = new Collection();
    for (const layer of layers) {
      features.extend(layer.getSource().getFeatures());
    }

    return features;
  }
}
