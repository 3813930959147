import { ISidebarRoutes } from '../models/sidebar.model';

export const ROUTES: ISidebarRoutes[] = [
  {
    title: 'Administracja',
    code: 'administracja',
    permissions: [],
    path: '/administration/users',
    children: [
      {
        title: 'Użytkownicy',
        path: '/administration/users',
        permissions: 'api_users_get_collection'
      },
      {
        title: 'Uprawnienia',
        path: '/administration/permissions',
        permissions: 'api_roles_get_collection'
      },
      {
        title: 'Struktura organizacyjna',
        path: '/administration/organization-structure',
        permissions: ''
      },
      {
        title: 'Konfiguracja cmentarza',
        path: '/administration/cemetery-settings',
        permissions: ''
      }
    ]
  },
  {
    title: 'Wod-Kan',
    code: 'wod-kan',
    permissions: [],
    path: '/wod-kan/advanced-map',
    children: [
      {
        path: '/wod-kan/advanced-map',
        title: 'Mapa',
        permissions: '',
        children: []
      },
      {
        path: '/wod-kan/documents/list',
        title: 'Dokumenty',
        permissions: 'api_attachments_get_collection',
        children: []
      },
      {
        path: '/wod-kan/accident/list',
        title: 'Awarie',
        permissions: 'api_awarias_get_collection',
        children: []
      },
      {
        path: '/wod-kan/repairs/list',
        title: 'Remonty',
        permissions: 'api_remonts_get_collection',
        children: []
      },
      {
        path: '/wod-kan/application/list',
        title: 'Zgłoszenia',
        permissions: 'api_zgloszenies_get_collection',
        children: []
      },
      {
        path: '/wod-kan/hydrants-review/list',
        title: 'Przegląd hydrantów',
        permissions: 'api_przeglad_hydrantus_get_collection',
        children: []
      },
      {
        path: '/wod-kan/video-inspections/list',
        title: 'Inspekcje video',
        permissions: 'api_inspekcje_videos_get_collection',
        children: []
      },
      {
        path: '/wod-kan/transmission-easement/list',
        title: 'Służebności przesyłu',
        permissions: 'api_sluzebnosc_przesylus_get_collection',
        children: []
      },
      {
        path: '/wod-kan/analysis/main',
        title: 'Analizy i raporty',
        permissions: '',
        children: []
      },
    ]
  },
  {
    title: 'Cmentarz',
    code: 'cmentarz',
    permissions: [],
    path: '/cemetery/search-engine/list',
    children: [
      {
        title: 'Wyszukiwarka',
        path: '/cemetery/search-engine/list'
      },
      {
        title: 'Mapa cmentarza',
        path: '/cemetery/map'
      },
      {
        title: 'Rezerwacje',
        path: '/cemetery/reservations'
      },
      {
        title: 'Opłaty',
        path: '/cemetery/payments/list'
      },
      {
        title: 'Pogrzeby',
        path: '/cemetery/funerals',
        permissions: 'api_cmentarz_pogrzebs_get_collection'
      },
      {
        title: 'Zamówienia',
        path: '/cemetery/orders/list'
      },
      {
        title: 'Księgi cmentarne',
        collapsed: true,
        children: [
          {
            title: 'Księga grobów',
            path: '/cemetery/graves-book'
          },
          {
            title: 'Księga pochowanych',
            path: '/cemetery/buried-book'
          }
        ]
      },
      {
        title: 'Raporty',
        collapsed: true,
        children: [
          {
            title: 'Groby opłacone - raport',
            path: '/cemetery/graves-reports/paid'
          },
          {
            title: 'Groby nieopłacone - raport',
            path: '/cemetery/graves-reports/unpaid'
          },
          {
            title: 'Ekshumacje',
            path: '/cemetery/buried/exhumation-report'
          }
        ]
      }
    ]
  },
  {
    title: 'CMS',
    code: 'cms',
    permissions: [],
    path: '/cms/page/list',
    children: [
      {
        title: 'Strony informacyjne',
        path: '/cms/page/list',
        permissions: 'api_cms_pages_get_collection'
      },
      {
        title: 'Bloki treści',
        path: '/cms/block/list',
        permissions: 'api_cms_blocks_get_collection'
      }
    ]
  },
  // {
  //   path: '/map',
  //   title: 'Mapa',
  //   code: '',
  //   permissions: '',
  //   children: []
  // }
];
