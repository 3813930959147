import {Injectable} from '@angular/core';
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from 'ol/style.js';

@Injectable()
export class StyleFactoryService {

  constructor() {}

  createDrawStyle(type: string) {
    if (type === 'Point') {
      return this.createDrawPointStyle();
    } else if (type === 'LineString') {
      return this.createDrawLineStyle();
    } else if (type === 'Polygon') {
      return this.createPolygonStyle();
    } else {
      return null;
    }
  }

  createPolygonStyle(): Style {
      return new Style({
        fill: new Fill({
          color: 'rgba(255, 0, 0, 0.2)'
        }),
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 0.8)',
          width: 2
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33'
          })
        })
      });
  }

  createWfsStyle(): Style {
    return new Style({
      fill: new Fill({
        color: 'rgba(255, 0, 0, 0.2)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 0.8)',
        width: 2
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: '#ffcc33'
        })
      })
    });
  }

  createDrawLineStyle(): Style {
    return new Style({
      stroke: new Stroke({
        color: '#ff0000',
        width: 2
      })
    });
  }

  createDrawPointStyle(): Style {
    return new Style({
      image: new Icon({
        src: 'assets/images/target.png',
        opacity: 1,
        scale: 0.4
      })
    });
  }

  createMeasuringStyle(): Style {
    return new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.7)'
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        })
      })
    });
  }

  createFinishedMeasureStyle(): Style {
    return new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 255, 0.8)',
        lineDash: [10, 10],
        width: 2
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.7)'
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        })
      })
    });
  }

  createSelectFeatureStyle(): Style {
    return new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 0, 0.8)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 1)',
        width: 2
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: '#ffcc33'
        })
      })
    });
  }

  createPointStyle(): Style {
    return new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: '#3399CC'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2
        })
      })
    });
  }

  createPrintPageStyle(): Style {
    return new Style({
      fill : new Fill({
        color : 'rgba(240, 248, 255, 0.7)'
      }),
      stroke : new Stroke({
        color : 'rgba(0, 191, 255, 0.8)',
        width : 4

      })
    });
  }

}
