import {GenericToolService} from './generic-tool-service';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {MapService} from '../services/map-service';
import Feature from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer.js';
import {DrawingEvent} from '../enums/drawing-event';
import {Draw, Modify, Snap, Select, Translate} from 'ol/interaction';
import {singleClick} from 'ol/events/condition';
import {MapConfigService} from '../services/map-config-servce';
import {StyleFactoryService} from '../services/style-factory-service';
import {MapHttpService} from '../services/map-http-service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {AttributesFormComponent} from './draw/attributes-form/attributes-form.component';
import {SnappingManagerService} from '../services/map/snapping-manager-service';
import {MapEventBusService} from '../services/map/map-event-bus-service';
import {DrawingChanges} from '../models/drawing-changes-model';
import {PendingChangesConfirmationComponent} from './draw/pending-changes-confirmation/pending-changes-confirmation.component';



@Injectable()
export class DrawToolService extends GenericToolService {

  @Output() featureModified = new EventEmitter();

  dialog: MatDialog;
  snappingService: SnappingManagerService;

  name = 'Draw';
  editableLayer: any;
  editedLayer: VectorLayer;
  showAttributesAfterDrawend = true;

  activeToolName: string;
  activeInteraction: any;
  currentTranslate: Translate;

  snap: Snap;

  changes = {
    added: [],
    modified: [],
    deleted: []
  };

  constructor(snappingService: SnappingManagerService, dialog: MatDialog, private eventBus: MapEventBusService,
              mapConfigService: MapConfigService, styleFactory: StyleFactoryService, mapHttpService: MapHttpService, _router: Router) {
    super(mapConfigService, styleFactory, mapHttpService, _router);
    this.dialog = dialog;
    this.snappingService = snappingService;
  }

  activate(mapService: MapService) {

    this.mapService = mapService;
    this.map = this.mapService.map;

    this.eventBus.drawingEvent.subscribe((data) => {
      this.handleDrawingEvent(data);
    });
  }

  handleDrawingEvent(data: any) {
    if (data.event === DrawingEvent.CHANGE_VECTOR_LAYER) {
      this.changeEditableLayer(data.layerName, false);
    } else if (data.event === DrawingEvent.ACTIVATE_DRAWING_TOOL) {
      this.activateDrawingTool(data);
    } else if (data.event === DrawingEvent.DRAWING_CONFIG_CHANGED) {
      this.updateConfiguration(data);
    }
  }

  changeEditableLayer(layerName, forced: boolean) {

    if (this.editableLayer) {
      if (!forced) {
        const changes = this.getChanges();
        if (this.changesExist(changes)) {
          this.showPendingChangesConfirmation(this.getChangesMessage(changes), {origin: 'changeLayer', layerName: layerName});
          return;
        }
      }
      this.editedLayer.getSource().currentlyEdited = undefined;
      this.map.removeLayer(this.editedLayer);
    }

    this.editableLayer = layerName;
    this.editedLayer = this.mapService.addWfsLayerFromWmsLayer(layerName);
  }

  activateDrawingTool(data: any) {
    if (data.toolName === 'add' || data.toolName === 'edit' || data.toolName === 'attributes' || data.toolName === 'move' || data.toolName === 'delete') {
      this.editedLayer.getSource().currentlyEdited = true;
      this.activateToggledTool(data.toolName);
    } else if (data.toolName === 'close') {
      this.closeEdit(false);
    } else if (data.toolName === 'save') {
      this.saveEditing();
    }
  }

  activateToggledTool(toolName: string) {


    this.deactivateActiveTool();
    if (this.activeToolName === toolName) {
      delete this.activeToolName;
      return;
    }

    this.activeToolName = toolName;
    if (toolName === 'add') {
      this.activateAddTool();
    } else if (toolName === 'edit') {
      this.activateEditTool();
    } else if (toolName === 'attributes') {
      this.activateAttributesTool();
    } else if (toolName === 'move') {
      this.activateMoveTool();
    } else if (toolName === 'delete') {
      this.activateDeleteTool();
    }
  }

  deactivateActiveTool() {
    this.map.removeInteraction(this.activeInteraction);
    delete this.activeInteraction;
  }

  updateConfiguration(data: any) {
    if (data.configName === 'showAttributesAfterDrawend') {
      this.showAttributesAfterDrawend = data.value;
    }
  }
  activateAddTool() {

    const type = this.getGeometryType();

    const draw = new Draw({
      source: this.editedLayer.getSource(),
      type: type,
      style: this.styleFactory.createDrawStyle(type)
    });

    const me = this;
    draw.on('drawend', function(evt) {
      evt.feature['action'] = 'add';
      me.handleAddDrawEnd(evt);
    });

    this.map.addInteraction(draw);
    this.activateSnapping();
    this.activeInteraction = draw;
  }

  activateEditTool() {

    const modify = new Modify({
      source: this.editedLayer.getSource()
    });

    const me = this;
    modify.on('modifyend', function(event) {
      me.handleUpdateDrawEnd(event);
    });

    this.map.addInteraction(modify);
    this.activateSnapping();
    this.activeInteraction = modify;
  }

  activateAttributesTool() {
    const select = new Select({
      layers: [this.editedLayer]
    });

    this.map.addInteraction(select);

    const me = this;
    select.on('select', function(event) {
      me.showAttributesWindow(event.selected[0], false);
    });

    this.activeInteraction = select;
  }

  activateMoveTool() {
    const select = new Select({
      layers: [this.editedLayer]
    });

    this.map.addInteraction(select);

    const me = this;
    select.on('select', function(event) {

      if (me.currentTranslate) {
        me.map.removeInteraction(me.currentTranslate);
      }

      const translate = new Translate({
        features: select.getFeatures()
      });

      me.currentTranslate = translate;
      me.map.addInteraction(translate);
    });

    this.activeInteraction = select;
  }

  activateDeleteTool() {
    const select = new Select({
      condition : singleClick,
      layers: [this.editedLayer]
    });

    this.map.addInteraction(select);

    const me = this;
    select.on('select', function(event) {

      const features = event.selected;

      if (!me.editedLayer.deletedFeatures) {
        me.editedLayer.deletedFeatures = [];
      }

      for (const feature of features) {
        const id = feature.getId();
        if (id) {
          me.editedLayer.deletedFeatures.push(id);
        }
        me.editedLayer.getSource().removeFeature(feature);
      }
      select.getFeatures().clear();
    });

    this.activeInteraction = select;

  }

  activateSnapping() {
    this.snappingService.updateSnappingLayer(this.editedLayer.layerConfig.layerName);
  }

  handleAddDrawEnd(evt) {
    evt.feature['action'] = 'add';

    const changes = this.getChanges();
    if (!changes.add) {
      changes.add = [];
    }
    changes.add.push(evt.feature);

    if (this.showAttributesAfterDrawend) {
      this.showAttributesWindow(evt.feature, true);
    }

    this.featureModified.emit();
    this.eventBus.drawChanged.emit(this.getChangesMessage(changes));
  }

  handleUpdateDrawEnd(evt) {
    this.featureModified.emit();
    this.eventBus.drawChanged.emit(this.getChangesMessage(this.getChanges()));
  }

  getChangesMessage(changes: any): string {

    let msg = '';

    if (changes.add && changes.add.length > 0) {
      msg += '  - Ilość dodanych obiektów: ' + changes.add.length +  '<br/>';
    }

    if (changes.update && changes.update.length > 0) {
      msg += '  - Ilość zaktualizowanych obiektów: ' + changes.update.length +  '<br/>';
    }

    if (changes.remove && changes.remove.length > 0) {
      msg += '  - Ilość usuniętych obiektów: ' + changes.remove.length +  '<br/>';
    }
    return msg;
  }

  getChanges(): DrawingChanges {

    const features = this.editedLayer.getSource().getFeatures();

    const changes = new DrawingChanges();
    const addAction = [];
    const modifyAction = [];

    for (const feature of features) {
      const properties = feature.getProperties();
      if (feature.action && feature.action === 'add') {
        addAction.push(feature);
      } else if (feature.getRevision() > 2) {
        modifyAction.push(feature);
      } else if (feature.getRevision() <= 2 && properties.action && properties.action === 'modified') { // attributes changed
        modifyAction.push(feature);
      }
    }

    if (addAction.length > 0) {
      changes.add = addAction;
    }

    if (modifyAction.length > 0) {
      changes.update = modifyAction;
    }

    const deleteAction = this.editedLayer.deletedFeatures;

    if (deleteAction && deleteAction.length > 0) {
      changes.delete = this.editedLayer.deletedFeatures;
    }

    return changes;

  }

  closeEdit(forced: boolean) {
    if (!forced) {
      const changes = this.getChanges();
      if (this.changesExist(changes)) {
        this.showPendingChangesConfirmation(this.getChangesMessage(changes), {origin: 'closeEdit'});
        return;
      }
    }

    this.cleanup();
  }

  cleanup() {
    this.cleanupActiveTool();
    this.map.removeLayer(this.editedLayer);
    this.editedLayer = undefined;
    this.editableLayer = undefined;
    this.eventBus.drawingSessionEnded.emit();
  }

  cleanupActiveTool() {
    if (this.currentTranslate) {
      this.map.removeInteraction(this.currentTranslate);
      this.currentTranslate = undefined;
    }
    this.map.removeInteraction(this.activeInteraction);
    this.activeInteraction = undefined;
    this.activeToolName = undefined;
    this.editedLayer.getSource().currentlyEdited = undefined;
    this.eventBus.toolDeactivated.emit();
  }

  changesExist(changes): boolean {
    if ((changes.add && changes.add.length > 0) || (changes.update && changes.update.length > 0)
      || (changes.delete && changes.delete.length > 0)) {
      return true;
    }
    return false;
  }

  showPendingChangesConfirmation(msg: string, eventData: any) {

    const dialogRef = this.dialog.open(PendingChangesConfirmationComponent, {
      data: {
        eventData: eventData,
        msg: msg
      },
      autoFocus: false,
      disableClose: true
    });

    dialogRef.componentInstance.confirmationAction.subscribe(data => {
      if (data.actionType === 'save' && data.origin === 'closeEdit') {
        this.saveEditing();
        this.closeEdit(true);
      } else if (data.actionType === 'close' && data.eventData.origin === 'closeEdit') {
        this.closeEdit(true);
      } else if (data.actionType === 'cancel' && data.eventData.origin === 'changeLayer') {
        this.eventBus.changeLayerCancelled.emit(this.editableLayer);
      } else if (data.actionType === 'save' && data.eventData.origin === 'changeLayer') {
        this.saveEditing();
        this.changeEditableLayer(data.eventData.layerName, true);
      } else if (data.actionType === 'close' && data.eventData.origin === 'changeLayer') {
        this.closeEdit(true);
        this.eventBus.selectEditableLayer.emit(data.eventData.layerName);
      }
      dialogRef.close();
    });
  }

  saveEditing() {
    const changes = this.getChanges();
    const param = this.parseFeatures(changes); // convert o geojson
    console.log('saving changes', param);
    this.cleanupActiveTool();
    this.refreshEditedLayer();
    this.eventBus.drawSaved.emit();
  }

  refreshEditedLayer() {
    // this.editedLayer.getSource().refresh();
    this.map.removeLayer(this.editedLayer);
    this.editedLayer = this.mapService.addWfsLayerFromWmsLayer(this.editableLayer);
  }

  parseFeatures(changes) {

    const param = {};

    if (changes.add) {
      const parsedAdd = [];
      for (const feature of changes.add) {
        parsedAdd.push(this.mapService.toFeatureGeojson(feature));
      }
      param['add'] = parsedAdd;
    }

    if (changes.update) {
      const parsedUpdate = [];
      for (const feature of changes.update) {
        parsedUpdate.push(this.mapService.toFeatureGeojson(feature));
      }
      param['update'] = parsedUpdate;
    }

    return param;
  }


  showAttributesWindow(feature: Feature, newObject: boolean) {
    let attributes: any;
    if (newObject) {
      attributes = this.getAttributesArray(this.editedLayer.getSource().getFeatures()[0]);
    } else {
      attributes = this.getAttributesArray(feature);
    }

    const dialogRef = this.dialog.open(AttributesFormComponent, {
      data: {
        attributes: attributes,
        newObject: newObject
      },
      width: '500px',
      // height: '500px'
    });

    dialogRef.componentInstance.saveAttributes.subscribe((attr) => {
      this.saveAttributes(attr, feature);
      dialogRef.close();
    });
  }

  saveAttributes(attributes: any, feature: Feature) {
    feature.setProperties(attributes);
  }

  getAttributesArray(feature: Feature) {
    const attributes = [];
    const properties = feature.getProperties();
    for (const key in properties) {
      if (key === 'geometry') {
        continue;
      }
      attributes.push({key: key, value: properties[key]});
    }
    return attributes;
  }


  getGeometryType() {
    return 'Polygon';
  }
}
