import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { LoginComponent } from './common/components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginService } from './common/components/login/services/login.service';
import { AuthGuard } from './common/app-permission/auth.guard';
import { AuthInterceptor } from './common/app-permission/auth.interceptor';
import { AppInitService } from './core/services/app-init.service';
import { ToastService } from './common/services/toast.service';
import { ToastDirective } from './common/services/toast.directive';
import { NgxPermissionsModule } from 'ngx-permissions';
import { APP_INITIALIZER } from '@angular/core';
import { AppInitializerService } from './core/services/app-initializer.service';
import { MenuService } from './common/services/menu.service';

export function init_app(appInitializerService: AppInitializerService) {
  return () => appInitializerService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ToastDirective
  ],
  imports: [
    BrowserModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [AppInitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitializerService],
      multi: true
    },
    MenuService,
    LoginService,
    AppInitService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ToastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
