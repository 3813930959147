import { Component, OnInit } from '@angular/core';

export interface InfoData {
  title: string;
  records: InfoRecord[];
}

export interface InfoRecord {
  key: string;
  value: any;
}

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {

  infoData: InfoData[];
  displayedColumns: string[] = ['key', 'value'];
  selectedId: string;

  constructor() { }

  ngOnInit() {
  }

  setInfoData(data: any) {
    if (!data || data.length === 0) {
      this.infoData = [];
      return;
    }

    this.selectedId = data[0].id;

    this.infoData = [];
    for (const info of data) {

      const keyValue = [];
      for (const attribute of info.attributes) {
        keyValue.push({key: attribute.label, value: attribute.value});
      }

      this.infoData.push({
        title: info.title,
        records: keyValue
      });
    }
  }

  selectInfo(id: string) {
    this.selectedId = id;
  }

  getSelectedInfoId() {
    return this.selectedId;
  }
}
