import { Component, OnInit, HostListener, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  @Input() name: string;
  @Input() nameInitials: string;
  @Input() jobPosition: string;

  isOpenTableSettings = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elemReferenc.nativeElement.contains(event.target)) {
      this.isOpenTableSettings = false;
    }
  }
  constructor(private elemReferenc: ElementRef, private _router: Router) { }

  ngOnInit() {
  }

  changeClosingComponentStatus() {
    this.isOpenTableSettings = !this.isOpenTableSettings;
  }

  changePassword() {
    this.changeClosingComponentStatus();
    this._router.navigate(['/administration/change-password']);
  }

  editUser() {
    this.changeClosingComponentStatus();
    this._router.navigate(['/administration/edit-user', localStorage.getItem('userId')]);
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['/login']);
  }

}
