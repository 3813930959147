import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {InfoPanelComponent} from '../info-panel/info-panel.component';

@Component({
  selector: 'app-object-assign-panel',
  templateUrl: './object-assign-panel.component.html',
  styleUrls: ['./object-assign-panel.component.scss']
})
export class ObjectAssignPanelComponent implements OnInit {

  @Output() objectAssigned = new EventEmitter();
  @Output() assignmentCancelled = new EventEmitter();

  @ViewChild(InfoPanelComponent, { static: true }) infoPanel;
  panelVisible = false;

  constructor() { }

  ngOnInit() {
  }

  setInfoData(data: any) {
    this.infoPanel.setInfoData(data);
    if (data.length > 0) {
      this.panelVisible = true;
    }
  }

  cancel() {
    this.assignmentCancelled.emit();
  }

  assignInspection() {
    const selectedInfoId = this.infoPanel.getSelectedInfoId();
    this.objectAssigned.emit(selectedInfoId);
  }
}
