import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {LayerTreeComponent} from './layer-tree/layer-tree.component';
import {MapConfigService} from '../../services/map-config-servce';
import {LayerFactoryService} from '../../services/layer-factory-service';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { fromLonLat } from 'ol/proj.js';
import {MapConfig} from '../../models/map-config.model';
import {MapService} from '../../services/map-service';
import { Vector as VectorLayer } from 'ol/layer.js';
import {AddGeometryToolService} from '../../tools/add-geometry-tool-service';
import {GenericToolService} from '../../tools/generic-tool-service';
import {EditGeometryToolService} from '../../tools/edit-geometry-tool-service';
import {MeasureToolService} from '../../tools/measure-tool-service';
import {MeasurementPanelComponent} from '../../tools/measurement-panel/measurement-panel.component';
import {ProjectionService} from '../../services/projection-service';
import {GeolocationToolService} from '../../tools/geolocation-tool-service';
import {EditingPanelComponent} from '../../tools/editing-panel/editing-panel.component';
import {PrintToolService} from '../../tools/print-tool-service';
import {PrintPanelComponent} from '../../tools/print-panel/print-panel.component';
import {RoutingToolService} from '../../tools/routing-tool-service';
import {MapHttpService} from '../../services/map-http-service';
import {Router} from '@angular/router';
import {ObjectAssignToolService} from '../../tools/object-assign-tool-service';
import {ObjectAssignPanelComponent} from '../../tools/object-assign-panel/object-assign-panel.component';
import {SnappingPanelComponent} from '../../tools/snapping-panel/snapping-panel.component';
import {GraveToolService} from '../../tools/grave-tool-service';
import {GravePanelComponent} from '../../tools/grave-panel/grave-panel.component';
import {InfoToolService} from '../../tools/info-tool-service';
import {InfoPanelComponent} from '../../tools/info-panel/info-panel.component';
import {DrawToolService} from '../../tools/draw-tool-service';
import {DrawingPanelComponent} from '../../tools/draw/drawing-panel/drawing-panel.component';
import {SnappingManagerService} from '../../services/map/snapping-manager-service';
import {defaults as defaultControls, OverviewMap, ScaleLine} from 'ol/control';
import {MapProfileChooserComponent} from '../../tools/map-profile-chooser/map-profile-chooser.component';
import {GraveInfoToolService} from '../../tools/grave-info-tool-service';
import {GraveInfoPanelComponent} from '../../tools/grave-info-panel/grave-info-panel.component';
import {LegendToolService} from '../../tools/legend-tool-service';
import {LegendPanelComponent} from '../../tools/legend-panel/legend-panel.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)',
        display: 'block'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)',
        display: 'none'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ],
  providers: [MapService, SnappingManagerService, AddGeometryToolService, EditGeometryToolService, MeasureToolService, PrintToolService,
    GraveToolService, RoutingToolService, ObjectAssignToolService, GeolocationToolService, InfoToolService, DrawToolService, GraveInfoToolService, LegendToolService]
})
export class MapComponent implements OnInit, AfterViewInit {

  @Output() navigationToolActivated = new EventEmitter();
  @Output() editToolToggled = new EventEmitter();
  @Output() geometryUpdated = new EventEmitter();
  @Output() mapInitiated = new EventEmitter();
  @Output() objectAssigned = new EventEmitter();

  @Input() mapType: string;
  @Input() measureTool: false;
  @Input() addTool: any;
  @Input() editTool: any;
  @Input() geolocationTool = false;
  @Input() printTool = false;
  @Input() routingTool = false;
  @Input() objectAssignTool = false;
  @Input() snappingTool: string;
  @Input() graveAddTool: string;
  @Input() infoTool = false;
  @Input() drawTool = false;
  @Input() minimapControl = false;
  @Input() profileChooser = false;
  @Input() initialData: any;
  @Input() graveInfoTool = false;
  @Input() legendTool = false;
  @Input() scaleControl = false;


  @ViewChild('layerTreeControl', { static: false }) layerTreeButtonRef: ElementRef;
  @ViewChild(LayerTreeComponent, { static: true }) treeComponent;
  @ViewChild(MeasurementPanelComponent, { static: true }) measurementPanel;
  @ViewChild(EditingPanelComponent, { static: true }) editingPanel;
  @ViewChild(PrintPanelComponent, { static: true }) printingPanel;
  @ViewChild(ObjectAssignPanelComponent, { static: true }) objectAssignPanel;
  @ViewChild(SnappingPanelComponent, { static: true }) snappingPanel;
  @ViewChild(GravePanelComponent, { static: true }) gravePanel;
  @ViewChild(InfoPanelComponent, { static: true }) infoPanel;
  @ViewChild(DrawingPanelComponent, { static: true }) drawingPanel;
  @ViewChild(MapProfileChooserComponent, { static: true }) profileChooserPanel;
  @ViewChild(GraveInfoPanelComponent, { static: true }) graveInfoPanel;
  @ViewChild(LegendPanelComponent, { static: true }) legendPanel;

  tools: string[] = [];
  activeTool: GenericToolService;
  toolInstances: {[key: string]: GenericToolService} = {};
  /**
   * any tool inieial data, generic model
   */
  toolInitialData: any = {};
  editableLayer: VectorLayer;

  mapConfig: MapConfig;
  mapReady = false;
  dataToLoad: string;

  activeToolPanel: string;
  toolPanelState = 'out';
  infoData: any;

  map: Map;

  constructor(
    public mapConfigService: MapConfigService,
    private layerFactory: LayerFactoryService,
    private projectionService: ProjectionService,
    public _router: Router,
    public mapService: MapService,
    public mapHttpService: MapHttpService,
    private addGeometryToolService: AddGeometryToolService,
    private editGeometryToolService: EditGeometryToolService,
    private measureToolService: MeasureToolService,
    private printToolService: PrintToolService,
    private graveToolService: GraveToolService,
    private routingToolService: RoutingToolService,
    private objectAssignToolService: ObjectAssignToolService,
    private geolocationToolService: GeolocationToolService,
    private infoToolService: InfoToolService,
    private drawToolService: DrawToolService,
    private snappingService: SnappingManagerService,
    private graveInfoToolService: GraveInfoToolService,
    private legendToolService: LegendToolService) { }

  ngOnInit() {
    if (this.mapType) {
      this.initMapComponent(this.mapType);
    }

    this.initToolButtons();

    if (this.initialData) {
      this.setInitialData(this.initialData);
    }
  }

  ngAfterViewInit() {
    this.initToolObservables();
  }

  initMapComponent(mapType: string) {
    this.mapType = mapType;
    this.mapConfigService.getMapConfig(mapType).subscribe(mapConfig => {
      this.initMap(mapConfig);
      this.mapService.init(this.map, this.mapConfig);

      this.treeComponent.layerVisibilityChanged.subscribe(value => {
        this.mapService.changeLayerVisibility(value.layerName, value.visible);
      });

      if (this.mapConfig.snappingLayers) {
        this.snappingPanel.setVectorLayers(this.mapConfig.snappingLayers, this.mapService.getLayersAttributeByProperty('name', 'type', 'wfs'));
      }

      if(this.dataToLoad) {
        this.mapService.loadSpatialData(this.dataToLoad);
        this.dataToLoad = undefined;
      }
    });
  }

  initToolObservables() {

    this.editingPanel.clearDrawing.subscribe(() => {
      this.addGeometryToolService.clearDrawing();
    });

    this.addGeometryToolService.geometryAdded.subscribe(geometry => {
      this.geometryUpdated.emit(geometry);
    });

    this.editGeometryToolService.geometryUpdated.subscribe(geometry => {
      this.geometryUpdated.emit(geometry);
    });

    this.measurementPanel.measureCleared.subscribe(() => {
      this.measureToolService.clearMeasurements();
    });

    this.measurementPanel.measureTypeChanged.subscribe(type => {
      this.measureToolService.changeMeasureType(this.map, type);
    });

    this.printingPanel.propertyChanged.subscribe((change) => {
      this.printToolService.handlePropertyChange(change);
    });

    this.printingPanel.togglePositioning.subscribe(() => {
      this.printToolService.togglePositioning();
    });

    this.printingPanel.print.subscribe((data) => {

      const filterConfig = [{
        property : 'printable',
        value : true
      }, {
        property : 'visible',
        value : true
      }];

      const layers = this.mapService.getLayersByConfigValues(filterConfig);

      this.printToolService.print(data, this.mapService.getSrs(), layers, this.mapService.getMaxExtent());
    });

    this.graveToolService.graveAdded.subscribe(geometry => {
      this.geometryUpdated.emit(geometry);
    });

    this.graveToolService.positioningClosed.subscribe(() => {
      this.gravePanel.togglePositioningButtons();
    });

    this.gravePanel.propertyChanged.subscribe((change) => {
      this.graveToolService.handlePropertyChange(change);
    });

    this.gravePanel.togglePositioning.subscribe(() => {
      this.graveToolService.togglePositioning();
    });

    this.gravePanel.zoomEvent.subscribe(() => {
      this.mapService.zoomToLayer(this.graveToolService.graveAddLayer);
    });

    // this.gravePanel.saveEvent.subscribe((data) => {
    //   this.graveToolService.saveGrave(data);
    // });

    this.objectAssignToolService.objectAssignFeatureInfoRetrieved.subscribe((features) => {
      this.objectAssignPanel.setInfoData(features);
    });

    this.objectAssignPanel.objectAssigned.subscribe((selectedId) => {
      this.objectAssigned.emit(selectedId);
    });

    this.objectAssignPanel.assignmentCancelled.subscribe(() => {
      this.toggleTool('ObjectAssign');
    });

    this.infoToolService.featureInfoReceived.subscribe((features) => {
      this.infoPanel.setInfoData(features);
    });

    this.graveInfoToolService.graveInfoReceived.subscribe((features) => {
      this.graveInfoPanel.setInfoData(features);
    });

    this.drawingPanel.snappingConfigChanged.subscribe((config) => {
      this.snappingService.updateSnappingParams(config);
    });

    this.profileChooserPanel.profileChanged.subscribe((configName) => {
      this.reloadMap(configName);
    });

    this.legendToolService.legendUrlCalculated.subscribe(url => {
      this.legendPanel.setLegendUrl(url);
    });

    if (this.graveAddTool) {
      this.graveToolService.initialize();
    }
  }

  initToolButtons() {

    this.tools.push('LayerTree');

    if (this.infoTool) {
      this.tools.push('InfoTool');
    }
    if (this.legendTool) {
      this.tools.push('LegendTool');
    }
    if (this.drawTool) {
      this.tools.push('DrawGeometryTool');
    }
    if (this.routingTool) {
      this.tools.push('Routing');
    }
    if (this.measureTool) {
      this.tools.push('Measure');
    }
    if (this.addTool || this.editTool) {
      this.tools.push('Draw');
    }
    if (this.geolocationTool) {
      this.tools.push('Geolocation');
    }
    if (this.printTool) {
      this.tools.push('Print');
    }
    if (this.objectAssignTool) {
      this.tools.push('ObjectAssign');
    }
    if (this.snappingTool) {
      this.tools.push('SnappingTool');
    }
    if (this.graveAddTool) {
      this.tools.push('GraveAddTool');
    }

    if (this.graveInfoTool) {
      this.tools.push('GraveInfoTool');
    }
  }

  initMap(mapConfig: MapConfig) {
    this.mapConfig = mapConfig;
    this.treeComponent.loadData(this.mapConfig.layers);
    const layers = this.layerFactory.createLayersFromConfiguration(this.mapConfig.layers, this.mapConfig.dataProjection);

    const controls = this.createControls();

    this.map = new Map({
      layers: layers,
      controls: controls,
      target: 'map',
      view: new View({
        center: fromLonLat(this.mapConfig.center),
        zoom: this.mapConfig.zoom
      })
    });

    this.mapReady = true;
    this.mapInitiated.emit();
  }

  reloadMap(mapConfigName: string) {

    if (this.mapType === mapConfigName) {
      return;
    }
    this.mapType = mapConfigName;
    this.mapConfigService.getMapConfig(mapConfigName).subscribe(mapConfig => {

      this.mapConfig = mapConfig;
      this.treeComponent.loadData(this.mapConfig.layers);
      const layers = this.layerFactory.createLayersFromConfiguration(this.mapConfig.layers, this.mapConfig.dataProjection);
      this.map.getLayers().clear();
      for (const layer of layers) {
        this.map.addLayer(layer);
      }

      const view = new View({
        center: fromLonLat(this.mapConfig.center),
        zoom: this.mapConfig.zoom
      });

      this.map.setView(view);
      this.mapService.init(this.map, this.mapConfig);

      if (this.mapConfig.snappingLayers) {
        this.snappingPanel.setVectorLayers(this.mapConfig.snappingLayers, this.mapService.getLayersAttributeByProperty('name', 'type', 'wfs'));
      }
    });

  }

  createControls() {
    const controls = defaultControls();

    if (this.minimapControl && this.mapConfig.minimapLayers && this.mapConfig.minimapLayers.length > 0) {
      const layers = this.layerFactory.createLayers(this.mapConfig.minimapLayers, this.mapConfig.dataProjection);
      const minimap = new OverviewMap({
        // see in overviewmap-custom.html to see the custom CSS used
        className: 'ol-overviewmap ol-custom-overviewmap',
        layers: layers,
        collapseLabel: '\u00BB',
        label: '\u00AB',
        collapsed: false
      });

      controls.extend([minimap]);
    }

    if (this.scaleControl) {
      const scaleControl = new ScaleLine({
        className: 'ol-custom-scale-line',
        units: 'metric'
      });

      controls.extend([scaleControl]);
    }

    return controls;
  }
  setInitialData(geometryJson: string) {
    if (this.map) {
      this.mapService.loadSpatialData(geometryJson);
    } else {
      this.dataToLoad = geometryJson;
    }
  }

  startEditing() {
    if (this.addTool || (this.editTool && !this.editableLayer)) {
      this.toggleTool('AddGeometry');
    } else if (this.editTool && this.editableLayer) {
      this.toggleTool('EditGeometry');
    }
  }

  toggleTool(toolName: string) {

    // deactivate current tool
    if (this.activeTool && this.activeTool.isActive) {
      this.activeTool.deactivate();
      this.activeTool.isActive = false;
    }

    if (this.activeTool && this.activeTool.name === toolName) {
      // means we deactivate active tool but do not activate another one
      this.activeTool = undefined;
    } else if (toolName === 'LayerTree') {
      this.activeTool = undefined;
    } else if (toolName === 'AddGeometry') {
      this.addGeometryToolService.activate(this.mapService, this.addTool);
      this.activeTool = this.addGeometryToolService;
    } else if (toolName === 'EditGeometry') {
      this.editGeometryToolService.activate(this.mapService, this.editTool);
      this.activeTool = this.editGeometryToolService;
    } else if (toolName === 'Measure') {
      this.measureToolService.activate(this.mapService, this.measurementPanel.selectedType);
      this.activeTool = this.measureToolService;
    } else if (toolName === 'Print') {

      this.mapConfigService.getPrintConfig(this.mapConfig.printConfigUrl).subscribe(response => {
        const printConfig = response;
        printConfig['closestScale'] = this.printToolService.getClosestScale(printConfig, this.mapService.getCurrentScale());
        printConfig['selectedTemplate'] = printConfig.layouts[3];
        this.printingPanel.setPrintConfig(response);
        this.printToolService.activate(this.mapService, printConfig);
        this.activeTool = this.printToolService;
      });

    } else if (toolName === 'GraveAdd') {
      this.graveToolService.activate(this.mapService, this.toolInitialData.graveToolInitialData);
      this.activeTool = this.graveToolService;
    } else if (toolName === 'Routing') {
      this.routingToolService.activate(this.mapService);
      this.activeTool = this.routingToolService;
    } else if (toolName === 'ObjectAssign') {
      this.objectAssignToolService.activate(this.mapService);
      this.activeTool = this.objectAssignToolService;
    } else if (toolName === 'Geolocation') {
      this.activeTool = this.geolocationToolService;
      this.geolocationToolService.activate(this.map);
    } else if (toolName === 'Snapping') {
      this.activeTool = undefined; // no actual tool, only a dedicated panel
      this.snappingPanel.setVectorLayers(this.mapConfig.snappingLayers, this.mapService.getLayersAttributeByProperty('name', 'type', 'wfs'));
    } else if (toolName === 'Info') {
      this.activeTool = this.infoToolService;
      this.infoToolService.activate(this.mapService);
    } else if (toolName === 'DrawGeometryTool') {
      this.map.updateSize();
      this.activeTool = this.drawToolService;
      this.drawToolService.activate(this.mapService);
      this.drawingPanel.loadEditableLayers(this.mapService.getLayersByProperty('editable', true));
    } else if (toolName === 'GraveInfo') {
      this.activeTool = this.graveInfoToolService;
      this.graveInfoToolService.activate(this.mapService);
    } else if (toolName === 'Legend') {
      this.activeTool = this.legendToolService;
      this.legendToolService.activate(this.mapService);
    }

    if (this.activeTool) {
      this.activeTool.isActive = true;
    }

    if (this.activeToolPanel === toolName) {
      this.toolPanelState = this.toolPanelState === 'out' ? 'in' : 'out';
    } else {
      this.activeToolPanel = toolName;
      this.toolPanelState = 'in';
    }
  }

  isToolActive(toolName: string): boolean {
    return this.activeTool && this.activeTool.name === toolName && this.activeTool.isActive;
  }

  deactivateTools() {
    if (this.activeTool) {
      this.activeTool.deactivate();
      this.activeTool = undefined;
      this.toolPanelState = 'out';
    }
  }

  changeGraveType(type) {
    this.graveToolService.handlePropertyChange({property: 'type', value:type});
  }

  getGraveGeometry() {
    if (this.graveToolService.isActive) {
      return this.graveToolService.getGeometry();
    }
    return null;
  }

  // getSnappingConfig() {
  //   return this.mapService.parseSnappingConfig(this.snappingPanel.getSnappingConfig(), this.snappingTool);
  // }
}
