// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiHost: 'https://netgis-api.dev.aspello.pl',
  apiPrefix: 'https://netgis-api.dev.aspello.pl/api/v1',
  availableApplication: 'cmentarz',
  nameApplication: 'Cmentarz',
  geoportalUrl: '',
  applicationVersion: '1.0.5',
  applicationAuthor: 'Netgis',
  loginBackground: '../../assets/images/login-background-wodkan2.jpg',
  loginTitle: 'Ruda Śląska - Cmentarz',
  appName: 'Netgis',
  baseRoute: '/cemetery/search-engine/list',
  defaultMap: 'cmentarz_ruda_app'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
