import {GenericToolService} from './generic-tool-service';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {MapService} from '../services/map-service';

@Injectable()
export class ObjectAssignToolService extends GenericToolService {

  @Output() objectAssignFeatureInfoRetrieved = new EventEmitter();

  name = 'ObjectAssign';
  clickableLayers: any[];
  registerableFunc: any;

  activate(mapService: MapService) {
    this.mapService = mapService;
    this.map = this.mapService.map;

    this.clickableLayers = this.getClickableLayers(this.mapService.getAllLayers());

    if (this.clickableLayers == null || this.clickableLayers.length === 0) {
      return;
    }

    //passing scope to singleclick not working in angular;
    const me = this;
    this.registerableFunc = function (evt) {
      me.executeGetFeatureInfo(evt);
    }

    this.map.on('singleclick', this.registerableFunc);

  }

  getClickableLayers(layers) {

    const resultLayers = [];

    for (const i in layers) {
       if (layers[i].layerConfig && layers[i].layerConfig.routingInfo) {
         resultLayers.push(layers[i]);
       }
    }

    return resultLayers;
  }

  executeGetFeatureInfo (evt) {

    const url = this.generateGetFeatureInfoUrl(evt);

    this.mapHttpService.getFeatureInfo(url).subscribe(response => {
      this.objectAssignFeatureInfoRetrieved.emit(response.features);
    });
  }

  generateGetFeatureInfoUrl(evt) {

    const viewResolution = this.map.getView().getResolution();
    const layerNames = this.getLayerNames();

    const params = {
      'INFO_FORMAT' : 'application/json',
      'QUERY_LAYERS' : layerNames,
      'LAYERS' : layerNames,
      'FEATURE_COUNT' : 50
    }

    params['BUFFER'] = 20;

    return this.clickableLayers[0].getSource().getGetFeatureInfoUrl(evt.coordinate, viewResolution, this.mapService.getSrs(), params);

  }

  getLayerNames() {
    let first = true;
    let names = '';
    for (const i in this.clickableLayers) {
      if (first) {
        names += this.clickableLayers[i].layerConfig.layerName;
        first = false;
      } else {
        names += ',' + this.clickableLayers[i].layerConfig.layerName;
      }
    }

    return names;
  }

  deactivate() {
    if (this.registerableFunc) {
      this.map.un('singleclick', this.registerableFunc);
    }
  }
}
